import React from 'react'

//import classnames from 'classnames';
import classes from '../styles/teachers.module.sass'
import { useStaticQuery, graphql } from 'gatsby'

export const Teachers = () => {
    const data = useStaticQuery(graphql`
      query TeacherDataQuery {
        site {
          siteMetadata {
            i18n {
              teachers {
                blocktitle
              }
            }
            teacherData {
              imgpath
              name
              jobtitle
            }
          }
        }
      }
    `);

    const { i18n } = data.site.siteMetadata;

    return (
        <div className={classes.container}>
            <h1>{i18n.teachers.blocktitle}</h1>
            <div className={classes.teachercards}>
                {data.site.siteMetadata.teacherData.map((item, index) => (
                    <div className={classes.teacher}>
                        <img src={item.imgpath} alt={item.jobtitle} className={classes.teacherimg}></img>
                        <p><b>{item.name}</b> <br></br>
                        <i>{item.jobtitle}</i></p>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Teachers

