import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import SEO from './seo'

import '../styles/index.sass'
import classes from '../styles/course-content-list.module.sass'
// import { useMsal } from '../utility/auth/msalContext';
// import { useEffect, useState } from 'react'


const CourseContentList = ({ title, description, children}) => {

    // const chapter_id = id //chapterData.chapter_id

    // const {progress} = useMsal()

    // const [doneCount, setDoneCount] = useState(0)

    

    const query = graphql`{
        site {
            siteMetadata {
                title
                description
                bio
                showProfileImage
            }
        }
    }`;

    /*
    useEffect(() => {        
          if(!progress) {
              console.debug("Failed to retrieve progress")
              return
          }
          console.debug("Progress is now loaded", progress);
                 
          const chapterFound = progress.modules.find(element => element.moduleId === chapter_id)
          let count = 0
          if (chapterFound) {
            
            chapterFound.sections.forEach((e) => {

                let complete = true

                e.exercises.forEach( (e) => {
                    if (JSON.parse(e.exerciseData).status === "uncomplete")
                        complete = false
                })
                if (e.exercises.length > 0 && complete)
                    count++
            })
            setDoneCount(count)
          } 

    }, [progress]);
    */

    return (
        

        <StaticQuery
            query={query}
            render={data => {
                return (
                    <>
                        <SEO title={title} description={description} />

                        <main className={classes.root}>


                            <div className={classes.markdownContentWrapper}>
                                <div className={classes.content}>
                                    {(title || description) && (
                                        <div className={classes.header}>
                                            {title && <h1 className={classes.title}>{title}</h1>}
                                            {description && (
                                                <p className={classes.description}>{description}</p>
                                            )}
                                        </div>
                                    )}
                                    {children}
                                </div>
                            </div>
                        </main>
                    </>
                )
            }}
        />
    )
}

export default CourseContentList
