
export const getCompletedExcerciseCount = (chapterId, taskId, progress, exercises) => {

    const chapterFound = progress.modules.find(element => element.moduleId === chapterId)

    if (chapterFound) {
    
    const taskFound = chapterFound.sections.find(element => element.sectionId === taskId)

    if (taskFound) {
        
        let count = 0 

        // Filters id:s client side which shouldn't exist in database but does so for some reason. For example if the id for an exercise has changed.
        // A better solution would be to just wipe those posts from the database. 
        
        const filteredExercises = taskFound.exercises.filter((elem1 => exercises.find(elem2 => elem2.properties.id === elem1.exerciseId) !== undefined))

        filteredExercises.forEach((e) => {
        if (JSON.parse(e.exerciseData).status === "complete") 
            count++                  
        })


        return count
    }
  }
}


export const getCompletedTaskCount = (chapter) => {


}
